var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// src/types.ts
var LogLevel = /* @__PURE__ */ ((LogLevel2) => {
  LogLevel2[LogLevel2["Fatal"] = 0] = "Fatal";
  LogLevel2[LogLevel2["Error"] = 1] = "Error";
  LogLevel2[LogLevel2["Warn"] = 2] = "Warn";
  LogLevel2[LogLevel2["Info"] = 3] = "Info";
  LogLevel2[LogLevel2["Debug"] = 4] = "Debug";
  LogLevel2[LogLevel2["Log"] = 5] = "Log";
  return LogLevel2;
})(LogLevel || {});

// src/reporters/console.ts
var consoleReporter = {
  write: (log) => {
    switch (log.level) {
      case 0 /* Fatal */:
        console.error(format(log));
        break;
      case 1 /* Error */:
        console.error(format(log));
        break;
      case 2 /* Warn */:
        console.warn(format(log));
        break;
      case 3 /* Info */:
        console.info(format(log));
        break;
      case 4 /* Debug */:
        console.debug(format(log));
        break;
      case 5 /* Log */:
        console.log(format(log));
        break;
    }
  }
};
function format(log) {
  const time = new Date(log.timestamp).toISOString();
  const tokens = [
    { value: time, separator: " | " },
    { value: LogLevel[log.level].toUpperCase(), separator: " | " },
    { value: log.scope ? `[${log.scope}]` : "", separator: ": " },
    { value: log.message }
  ];
  if (log.extras) tokens.push({ value: JSON.stringify(log.extras) });
  tokens.push({ value: `[${log.elapsed}ms]` });
  let line = "";
  for (const token of tokens) {
    if (token.value) {
      line += token.value;
      if (token.separator) line += token.separator;
      else line += " ";
    }
  }
  return line;
}

// src/index.ts
var Logger = class _Logger {
  constructor(config = {
    reporter: consoleReporter,
    lastTime: Date.now()
  }) {
    this.config = config;
    this.fatal = errorLogLevelFactory(0 /* Fatal */, () => this.config);
    this.warn = logLevelFactory(2 /* Warn */, () => this.config);
    this.debug = logLevelFactory(4 /* Debug */, () => this.config);
    this.error = errorLogLevelFactory(1 /* Error */, () => this.config);
    this.info = logLevelFactory(3 /* Info */, () => this.config);
    this.log = logLevelFactory(5 /* Log */, () => this.config);
  }
  scope(scope) {
    return new _Logger(__spreadProps(__spreadValues({}, this.config), { scope }));
  }
  measure(tag) {
    performance.mark(tag);
    const marks = performance.getEntriesByName(tag, "mark");
    if (marks.length === 2) {
      const duration = marks[1].startTime - marks[0].startTime;
      this.info(`${tag} took ${duration.toFixed(2)}ms`);
      performance.clearMarks(tag);
    }
  }
};
var NoopLogger = class {
  fatal() {
  }
  warn() {
  }
  debug() {
  }
  error() {
  }
  info() {
  }
  log() {
  }
  measure() {
  }
  scope() {
    return this;
  }
  replaceWith(logger) {
    Object.assign(this, logger);
  }
};
function logLevelFactory(level, getConfig) {
  return (message, extras) => {
    const now = Date.now();
    const config = getConfig();
    config.reporter.write({
      level,
      message,
      timestamp: now,
      extras,
      scope: config.scope,
      elapsed: now - config.lastTime
    });
    config.lastTime = now;
  };
}
function errorLogLevelFactory(level, getConfig) {
  return (error, fallbackMessage, extras) => {
    const now = Date.now();
    const config = getConfig();
    config.reporter.write({
      level,
      message: error instanceof Error && error.stack ? error.stack.trim() : fallbackMessage ? fallbackMessage : "An unknown error occurred.",
      timestamp: now,
      extras: error instanceof Error ? __spreadProps(__spreadValues({}, extras), { fallbackMessage }) : error ? __spreadProps(__spreadValues({}, extras), { error }) : extras,
      scope: config.scope,
      elapsed: now - config.lastTime
    });
    config.lastTime = now;
  };
}
function combineReporters(reporters) {
  return {
    write(log) {
      for (const reporter of reporters) {
        reporter.write(log);
      }
    }
  };
}
export {
  LogLevel,
  Logger,
  NoopLogger,
  combineReporters,
  consoleReporter,
  format
};
